<template>
  <div>
    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-5">
        <v-card
          v-if="getUserAddress && nfts.length"
          class="mx-auto text-center mb-5"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="text-h7 mb-1">
                <h3 id="endOfDay">0 Hours 0 Minutes 0 Seconds</h3>
              </v-list-item-title>
              <h4 class="font-weight-regular">
                until the next days HEX Rewards will be available for
                withdrawal.
              </h4>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <v-card class="pa-3 text-center">
          <a
            :href="ETHERSCAN_URL_BASE + 'address/' + HEX_REWARDS_ADDRESS"
            target="_blank"
          >
            <h1 class="colors">PulseRich HEX Rewards</h1>
          </a>

          <br />Coming Soon!
          <!--

<br />You have <b>{{ nfts.length }} NFTs</b>
<br />You Can Withdraw:
<b>{{ totalRewardOwed }} $HEX</b> <br />The Total Pool Holds:
<b>{{ eightFormat(hexInContract) }} $HEX</b>
<v-divider class="my-5"></v-divider>

<div v-if="!getUserAddress" class="d-flex justify-center">
  <p class="my-10">Connect your wallet to see your NFT's</p>
</div>
<div v-else-if="isNFTLoading" class="d-flex justify-center my-10">
  <v-progress-circular
    indeterminate
    color="primary"
  ></v-progress-circular>
</div>
<div v-else-if="!nfts.length">
  <p class="mt-5 text-center">
    You don't have any PulseRich NFT's
  </p>
  <div>
    <router-link :to="{ name: 'Mint' }">
      <v-btn small class="px-4" color="info">Go Mint </v-btn>
    </router-link>
  </div>
</div>
<template v-else>
  <div class="row">
    <template v-for="(nft, i) in nfts">
      <div class="col-12" :key="i">
        <div class="text-center">
          <b>NFT# {{ nft.id }}</b> <br />


          <div @click="toggleImage(nft.id)">
            <img class="nft_img" :src="nft.image" />
          </div>

          <transition name="expand">
            <div
                v-if="expandedImage === nft.id"
                class="expanded-overlay"
            >
              <img :src="nft.image" alt="NFT" />
              <span class="close-button" @click="collapseImage">&times;</span>
            </div>
          </transition>


          <small style="font-size: 9px" v-if="!nft.isRegistered">
            Register Today and Start Earning 1 Hex per day
          </small>

          <small style="font-size: 9px" v-if="nft.isRegistered">
            Days Since Last Withdrawal:
            {{ currentDay - nft.withdrawalDay }}
            {{ isPlural((currentDay - nft.withdrawalDay) | 0) }}
          </small>
          <br />
          <small style="font-size: 9px" v-if="nft.isRegistered">
            Earning {{ nft.reward / 1e8 }} HEX daily
          </small>

          <div class="d-flex justify-center mt-4">
            <v-btn
              v-if="
                nft.isRegistered &&
                !cannotWithdrawHex(nft.withdrawalDay)
              "
              small
              class="px-4 mr-2"
              color="mt-1 info"
              @click="onWithdraw(nft.id)"
              >{{
                `Withdraw ${eightFormat(
                  (currentDay - nft.withdrawalDay) * nft.reward,
                  2
                )} HEX!`
              }}
            </v-btn>

            <v-btn
              v-else-if="
                nft.isRegistered &&
                cannotWithdrawHex(nft.withdrawalDay)
              "
              small
              class="px-4 mr-2"
              color="mt-1 info"
              :disabled="true"
            >
              Please await countdown <br />
              completion for withdrawal
            </v-btn>

            <v-btn
              v-else
              small
              class="px-5 ml-2"
              color="mt-1 success"
              @click="onRegister(nft.id)"
            >
              Register
            </v-btn>
          </div>
        </div>

        <v-divider class="my-5"></v-divider>
      </div>
    </template>
  </div>
  <div class="d-flex justify-center">
    <v-btn
      small
      class="px-4 mr-2"
      color="mt-1 info"
      :disabled="shouldDisableBulkWithdrawal"
      @click="onBulkWithdraw()"
      >Bulk Withdraw
    </v-btn>
    <v-btn
      small
      class="px-5 ml-2"
      color="mt-1 success"
      :disabled="shouldDisableBulkRegistration"
      @click="onBulkRegister()"
      >Bulk Register
    </v-btn>
  </div>

  <small
    v-show="
      getBulkRegisterPulseRichFee(
        NftsThatCanBeRegistered.map((nft) => nft.id)
      ) > 0
    "
  >
    Bulk Register Fee:
    {{
      getBulkRegisterPulseRichFee(
        NftsThatCanBeRegistered.map((nft) => nft.id)
      ) / 1e18
    }}
    PULSERICH
  </small>
</template>
-->
        </v-card>
      </div>
    </v-row>
  </div>
</template>
<script>
export default {
  name: "PLSRewards",
  data() {
    return {
      nfts: [],
      isNFTLoading: true,
      pulseRichAllowance: false,
      clearIntervalX: null,
      currentDay: 0,
      hexInContract: 0,
      expandedImage: null,
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },
  async mounted() {
    if (this.getUserAddress) this.readValues();
  },
  methods: {
    async readValues() {
      let pulseRichAllowance = await this.getRIDEInstance.methods
        .allowance(this.getUserAddress, this.HEX_REWARDS_ADDRESS)
        .call();
      this.pulseRichAllowance = Number(pulseRichAllowance);

      let currentRegistrationFee = await this.getHEXRewardsInstance.methods
        .RICH_FEE()
        .call();
      this.currentRegistrationFee = Number(currentRegistrationFee);

      this.currentDay = await this.getHEXRewardsInstance.methods
        .currentDay()
        .call();

      this.hexInContract = await this.getHEXInstance.methods
        .balanceOf(this.HEX_REWARDS_ADDRESS)
        .call();

      // console.log("this.pulseRichAllowance:", this.pulseRichAllowance);
      clearInterval(this.clearIntervalX);
      this.counter();
      this.getUserNFTs();
    },

    async getUserNFTs() {
      this.nfts = [];
      this.isNFTLoading = true;
      try {
        let ids = await this.getMINTINGInstance.methods
          .getTokenIdsByWallet(this.getUserAddress, 0, 10000)
          .call();
        let nfts = ids.map((m) => {
          return { id: m };
        });
        if (nfts.length) {
          nfts.forEach((nft, i) => {
            nft.name = `${nft.id}.png`;
            let tokenURI = this.geturi(nft.id);
            Promise.all([
              this.getHEXRewardsInstance.methods
                .tokenIdsToRegistered(nft.id)
                .call(),
              this.getHEXRewardsInstance.methods
                .tokenIdsToLastWithdrawalDay(nft.id)
                .call(),
              this.getHEXRewardsInstance.methods.dailyRewardAmount().call(),
              this.getMetadata(tokenURI),
            ]).then(([isRegistered, withdrawalDay, reward, metaData]) => {
              nft.isRegistered = isRegistered;
              nft.withdrawalDay = withdrawalDay;
              nft.reward = reward;
              nft.name = metaData.name;
              nft.image = metaData.image.replace(/^ipfs:\/\/(.+)/, "https://carn.infura-ipfs.io/ipfs/$1");
              nft.description = metaData.description;

              this.nfts.push(nft);
              if (i + 1 === nfts.length) {
                this.nfts.sort(this.compareValues("id"));
              }
              this.isNFTLoading = false;
            });
          });
        } else {
          this.isNFTLoading = false;
        }
      } catch (error) {
        this.isNFTLoading = false;
      }
    },

    onRegister(tokenId) {
      if (!this.pulseRichAllowance) {
        this.onPulseRichApprove();
        return;
      }
      this.SET_LOADING(true);
      this.getHEXRewardsInstance.methods
        .registerNftForRewards(tokenId)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Register transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Register transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Register transaction has Failed");
        });
    },

    onWithdraw(tokenId) {
      this.SET_LOADING(true);
      this.getHEXRewardsInstance.methods
        .withdrawRewards(tokenId)
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Withdraw Rewards transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw Rewards transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Withdraw Rewards transaction has Failed");
        });
    },

    onBulkRegister() {
      let tokenIds = this.nfts.filter((f) => !f.isRegistered);
      if (tokenIds.length) tokenIds = tokenIds.map((m) => m.id);

      let start = this.currentRegistrationFee;
      let totalPulseRichForBulkRegistration = 0;
      for (let i = 0; i < tokenIds.length; i++) {
        totalPulseRichForBulkRegistration += start + i * 1e18;
      }
      console.log(
        "totalPulseRichForBulkRegistration: ",
        totalPulseRichForBulkRegistration
      );
      console.log("pulseRichAllowance: ", this.pulseRichAllowance);

      if (this.pulseRichAllowance < totalPulseRichForBulkRegistration) {
        this.onPulseRichApprove();
        return;
      }
      let baseTxnFee = 20000;
      let txnFee = baseTxnFee * tokenIds.length;

      if (txnFee < baseTxnFee * 10) {
        txnFee = baseTxnFee * 10;
      }
      console.log("txnFee: ", txnFee);

      console.log("result:", tokenIds);
      this.SET_LOADING(true);
      this.getHEXRewardsInstance.methods
        .bulkRegister(tokenIds)
        .send({
          from: this.getUserAddress,
          value: this.getWeb3.utils.toWei(txnFee.toString(), "ether"),
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Bulk register transaction is Processing!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Bulk register transaction has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Bulk register transaction has Failed");
        });
    },

    onBulkWithdraw() {
      let tokenIds = this.NftsThatCanBeWithdrawed.map((nft) => nft.id);
      console.log("result:", tokenIds);
      let baseTxnFee = 20000;
      let txnFee = baseTxnFee * tokenIds.length;

      if (txnFee < baseTxnFee * 10) {
        txnFee = baseTxnFee * 10;
      }
      console.log("txnFee: ", txnFee);

      this.SET_LOADING(true);
      this.getHEXRewardsInstance.methods
        .bulkWithdraw(tokenIds)
        .send({
          from: this.getUserAddress,
          value: this.getWeb3.utils.toWei(txnFee.toString(), "ether"),
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show(
            "Bulk withdraw Rewards transaction is Processing!"
          );
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show(
            "Bulk withdraw Rewards transaction has Completed!"
          );
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Bulk withdraw Rewards transaction has Failed");
        });
    },

    // CARN
    onPulseRichApprove() {
      this.SET_LOADING(true);
      this.getRIDEInstance.methods
        .approve(this.HEX_REWARDS_ADDRESS, "1000000000000000000000000000")
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log(hash);
          this.$toasted.show("Approving PulseRich!");
        })
        .on("receipt", (receipt) => {
          console.log(receipt);
          this.readValues();
          this.SET_LOADING(false);
          this.$toasted.show("Approving PulseRich has Completed!");
        })
        .on("error", (error, receipt) => {
          console.log(error, receipt);
          this.SET_LOADING(false);
          this.$toasted.show("Approving PulseRich has Failed");
        });
    },

    compareValues(key) {
      return (a, b) => {
        let comparison = 0;
        if (a[key] > b[key]) {
          comparison = 1;
        } else if (a[key] < b[key]) {
          comparison = -1;
        }
        return comparison;
      };
    },

    counter() {
      const endOfDay = new Date().setUTCHours(23, 59, 59, 999);
      this.clearIntervalX = setInterval(function () {
        let now = new Date().getTime();
        const distance = endOfDay - now;
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalX);
          document.getElementById(
            "endOfDay"
          ).innerHTML = `0 Hours 0 Minutes 0 Seconds`;
        } else {
          document.getElementById(
            "endOfDay"
          ).innerHTML = `${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },

    geturi(id) {
      // return `https://carn.infura-ipfs.io/ipfs/bafybeiety6jf6igze5wfvcz3dbr6prqhompbfo3kdixiajarfic6nilyee/${id}.png`;
      return `https://carn.infura-ipfs.io/ipfs/bafybeichpu4pggrl4u3ea6e7pi5cwgzurrbh5n2zm44w4fbfssb6lsww4i/${id}.json`;

    },
    cannotWithdrawHex(lastWithdrawalDay) {
      // console.log(typeof lastWithdrawalDay )
      // console.log(typeof this.currentDay )
      return Number(this.currentDay) <= Number(lastWithdrawalDay);
    },
    getBulkRegisterPulseRichFee(tokenIds) {
      let start = this.currentRegistrationFee;
      let totalPulseRichForBulkRegistration = 0;
      // console.log(totalPulseRichForBulkRegistration);
      for (let i = 0; i < tokenIds.length; i++) {
        totalPulseRichForBulkRegistration += start + i * 1e18;
      }
      return totalPulseRichForBulkRegistration;
    },
    toggleImage(imageId) {
      console.log("toggle")

      if (this.expandedImage === imageId) {
        console.log("if collapse")

        this.collapseImage();

      } else {
        console.log("if expands")

        this.expandImage(imageId);
      }
    },
    expandImage(imageId) {
      console.log("expands")

      this.expandedImage = imageId;
    },
    collapseImage() {
      console.log("collapse")
      this.expandedImage = null;
    },

  },
  computed: {
    NftsThatCanBeRegistered() {
      return this.nfts.filter((nft) => nft.id && !nft.isRegistered);
    },
    shouldDisableBulkRegistration() {
      // console.log("this.NftsThatCanBeRegistered.length: ",this.NftsThatCanBeRegistered.length)
      return this.NftsThatCanBeRegistered.length === 0;
    },
    NftsThatCanBeWithdrawed() {
      return this.nfts.filter(
        (nft) =>
          !this.cannotWithdrawHex(nft.withdrawalDay) &&
          nft.id &&
          nft.isRegistered
      );
    },
    shouldDisableBulkWithdrawal() {
      // console.log("this.NftsThatCanBeRegistered.length: ",this.NftsThatCanBeWithdrawed.length)
      return this.NftsThatCanBeWithdrawed.length === 0;
    },
    totalRewardOwed() {
      let totalReward = 0;

      for (let i = 0; i < this.nfts.length; i++) {
        // console.log("i: ",i);
        const nft = this.nfts[i];
        // console.log("nft id: ",nft.id);

        if (nft.isRegistered && !this.cannotWithdrawHex(nft.withdrawalDay)) {
          // console.log("nft currentDay: ",this.currentDay);
          // console.log("nft withdrawalDay: ",nft.withdrawalDay);
          // console.log("nft reward: ", Number(nft.reward)/1e8);

          totalReward +=
            ((this.currentDay - nft.withdrawalDay) * Number(nft.reward)) / 1e8;
        }
        // console.log("totalReward: ",totalReward)
        // console.log('*********')
      }
      return totalReward;
    },
  },
  watch: {
    getUserAddress() {
      if (this.getUserAddress) this.readValues();
    },
  },
};
</script>
<style>
.nft_img {
  width: 325px;
  height: 325px;
  display: block;
  margin: auto;
  border-radius: 10px;
  border: 1px solid grey;
}

.v-main.FreeCarn .v-main__wrap {
  background-image: url("../assets/hexlogos2.jpg");
  background-size: 200%;
  background-position: center;
}

.colors {
  --bg-size: 400%;
  font-family: sans-serif;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) 0 0 /
    var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .colors {
    animation: move-bg 30s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



.expanded-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the overlay appears on top */

}

.expanded-overlay img {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 100px;
  cursor: pointer;
}
</style>
