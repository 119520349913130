<template>
  <v-app id="inspire" :class="isLoading ? 'loading' : ''">
    <div class="main-loader" v-if="isLoading">
      <img src="@/assets/LogoTransparent.png" style="width: 100px" />
    </div>
    <v-app-bar app>
      <router-link :to="{ name: 'Mint' }">
        <img
          class="logo"
          src="@/assets/kb_logo_2.png"
        />

      </router-link>
      <v-spacer></v-spacer>
      <v-tabs v-if="!$vuetify.breakpoint.mobile" align-with-title>
        <v-tab :to="{ name: 'Mint' }" exact>Step 1: Mint</v-tab>
        <v-tab :to="{ name: 'PLSRewards' }" exact>Step 2: PLS Rewards</v-tab>

<!--        <v-tab :to="{ name: 'HEXRewards' }" exact>HEX Rewards</v-tab>-->
<!--        <v-tab :to="{ name: 'Lock' }" exact>PULSERICH</v-tab>-->
<!--        <v-menu v-if="moreRewardPages.length" :offset-y="true">-->
<!--          <template v-slot:activator="{ on, attrs }">-->
<!--            <v-tab v-bind="attrs" v-on="on">-->
<!--              More-->
<!--              <v-icon right> mdi-menu-down </v-icon>-->
<!--            </v-tab>-->
<!--          </template>-->

<!--          <v-list class="grey lighten-3">-->
<!--            <v-list-item-->
<!--              v-for="reward in moreRewardPages"-->
<!--              :key="reward"-->
<!--              :to="{ name: reward.link }"-->
<!--              exact-->
<!--            >-->
<!--              {{ reward.name }}-->
<!--            </v-list-item>-->
<!--          </v-list>-->
<!--        </v-menu>-->
      </v-tabs>
      <template v-if="$vuetify.breakpoint.mobile" v-slot:extension>
        <v-tabs align-with-title>
          <v-tab :to="{ name: 'Mint' }" exact>Step 1: Mint</v-tab>
          <v-tab :to="{ name: 'PLSRewards' }" exact>Step 2: PLS Rewards</v-tab>
<!--          <v-tab :to="{ name: 'HEXRewards' }" exact>HEX Rewards</v-tab>-->
<!--          <v-tab :to="{ name: 'Lock' }" exact>PULSERICH</v-tab>-->
<!--          <v-menu v-if="moreRewardPages.length" :offset-y="true">-->
<!--            <template v-slot:activator="{ on, attrs }">-->
<!--              <v-tab v-bind="attrs" v-on="on">-->
<!--                More-->
<!--                <v-icon right> mdi-menu-down </v-icon>-->
<!--              </v-tab>-->
<!--            </template>-->

<!--            <v-list class="grey lighten-3">-->
<!--              <v-list-item-->
<!--                v-for="reward in moreRewardPages"-->
<!--                :key="reward"-->
<!--                :to="{ name: reward.link }"-->
<!--                exact-->
<!--              >-->
<!--                {{ reward.name }}-->
<!--              </v-list-item>-->
<!--            </v-list>-->
<!--          </v-menu>-->
        </v-tabs>
      </template>
      <v-spacer></v-spacer>
      <v-btn id="connect-wallet" @click="onConnect" color="primary" :readonly="!!getUserAddress">
        {{ getUserAddress ? addrTruncation(getUserAddress) : "Connect Wallet" }}
      </v-btn>
    </v-app-bar>

    <v-main :class="$route.name">
      <div class="pa-5 pa-sm-8" style="height: 100%">
        <router-view />
      </div>
      <v-footer dark padless app>
        <v-card class="flex" flat tile>
          <v-card-title class="black">
            <v-tab  id="white-paper" :to="{ name: 'WhitePaper' }" exact>White Paper</v-tab>
            <v-tab :to="{ name: 'Disclaimer' }" exact>Disclaimer</v-tab>
<!--            <v-tab :to="{ name: 'VIP' }" exact>V.I.P</v-tab>-->

            <v-spacer></v-spacer>
            <v-btn class="mx-4" dark icon href="https://twitter.com/PulseRichApp" target="_blank">
              <div style="font-size: xx-large"> 𝕏</div>
            </v-btn>
            <v-btn class="mx-4" dark icon href="https://t.me/PulseRichApp" target="_blank">
              <img
                src="https://static-00.iconduck.com/assets.00/telegram-icon-2048x1739-6c7sghxm.png"
                width="24px"
                alt=""
              />
            </v-btn>
          </v-card-title>
        </v-card>
      </v-footer>
    </v-main>
  </v-app>
</template>

<script>
import Web3 from "web3";
import Web3Modal from "web3modal";
import ABIS from "@/config/ABIS.json";
import { mapGetters } from "vuex";
export default {
  name: "App",
  data() {
    return {
      provider: null,
      web3Modal: null,
      isAlreadyConnected: false,
      moreRewardPages: [
        {
          name: "XYZ Rewards",
          link: "/",
        },
        {
          name: "ABC Rewards",
          link: "/",
        },
      ],
    };
  },

  beforeMount() {
    const providerOptions = {
      walletconnect: {
        options: {
          rpc: {
            1: "https://mainnet.infura.io/v3/",
          },
          chainId: 1,
          network: "binance",
          infuraId: "2af64799935b4be086c072d13f0dad73",
        },
      },
    };

    this.web3Modal = new Web3Modal({
      providerOptions,
      cacheProvider: false,
      disableInjectedProvider: false,
    });
  },
  mounted() {
    if (window.ethereum) {
      window.ethereum
        .request({ method: "eth_accounts" })
        .then((accounts) => {
          if (accounts.length) {
            this.provider = window.ethereum;
            this.onProvider();
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  },
  methods: {
    viewTour(){
      this.$tours['myTour'].start()
    },
    async onConnect() {
      try {
        this.provider = await this.web3Modal.connect();
        this.onProvider();
        if (!this.isAlreadyConnected) {
          this.provider.on("accountsChanged", (accounts) => {
            console.log(accounts);
            this.onProvider();
          });

          this.provider.on("chainChanged", (chainId) => {
            console.log(chainId);
            this.onProvider();
          });
        }
      } catch (e) {
        //console.log("Could not get a wallet connection", e);
        return;
      }
    },

    async onProvider() {
      this.isAlreadyConnected = true;
      let web3 = new Web3(this.provider);
      let accounts = await web3.eth.getAccounts();
      let chainId = await web3.eth.getChainId();
      if (chainId !== this.CHAIN_ID) {
        try {
          await window.ethereum.request({
            method: "wallet_switchEthereumChain",
            params: [{ chainId: `0x${this.CHAIN_ID.toString(16)}` }],
          });
          this.onProvider();
          return;
        } catch (switchError) {
          this.$toasted.show(
            `Only Ethereum ${this.NETWORK} Network is supported`
          );
          return;
        }
      }
      let RIDE_INSTANCE = new web3.eth.Contract(
        ABIS.RIDE_ABI,
        this.RIDE_ADDRESS
      );

      let LOCK_INSTANCE = new web3.eth.Contract(
        ABIS.LOCK_ABI,
        this.LOCK_ADDRESS
      );
      let MINTING_INSTANCE = new web3.eth.Contract(
        ABIS.MINTING_ABI,
        this.MINTING_ADDRESS
      );
      let VIP_INSTANCE = new web3.eth.Contract(
          ABIS.VIP_ABI,
          this.VIP_ADDRESS
      );
      let USDC_INSTANCE = new web3.eth.Contract(
        ABIS.USDC_ABI,
        this.USDC_ADDRESS
      );
      let HEX_INSTANCE = new web3.eth.Contract(ABIS.HEX_ABI, this.HEX_ADDRESS);
      let eHEX_INSTANCE = new web3.eth.Contract(
        ABIS.eHEX_ABI,
        this.eHEX_ADDRESS
      );
      let PLSX_INSTANCE = new web3.eth.Contract(
        ABIS.PLSX_ABI,
        this.PLSX_ADDRESS
      );

      let PLS_REWARDS_INSTANCE = new web3.eth.Contract(
        ABIS.PLS_REWARDS_ABI,
        this.PLS_REWARDS_ADDRESS
      );

      let HEX_REWARDS_INSTANCE = new web3.eth.Contract(
        ABIS.HEX_REWARDS_ABI,
        this.HEX_REWARDS_ADDRESS
      );

      this.SET_WEB3(web3);
      this.SET_USER_ADDRESS(accounts[0]);

      this.SET_RIDE_INSTANCE(RIDE_INSTANCE);
      this.SET_LOCK_INSTANCE(LOCK_INSTANCE);
      this.SET_MINTING_INSTANCE(MINTING_INSTANCE);
      this.SET_VIP_INSTANCE(VIP_INSTANCE);
      this.SET_USDC_INSTANCE(USDC_INSTANCE);
      this.SET_HEX_INSTANCE(HEX_INSTANCE);
      this.SET_eHEX_INSTANCE(eHEX_INSTANCE);
      this.SET_PLSX_INSTANCE(PLSX_INSTANCE);
      this.SET_PLS_REWARDS_INSTANCE(PLS_REWARDS_INSTANCE);
      this.SET_HEX_REWARDS_INSTANCE(HEX_REWARDS_INSTANCE);
      this.$toasted.show("Wallet Connected Successfully");
    },
  },
  computed: {
    ...mapGetters("wallet", ["isLoading"]),
  },
};
</script>
<style>
@import "./styles/main.css";
</style>
