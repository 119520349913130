<template>
  <tr>
    <td class="text-center">
      <img
        :src="getImgUrl('pulse_logo.png')"
        alt=""
        width="50"
        style="margin: 10px 0"
      />
    </td>
    <td class="text-center">{{ totalRewardOwed }} $PLS</td>
    <td class="text-center">{{ eighteenFormat(plsInContract) }} $PLS</td>
    <td class="text-center">{{ currentDay }}</td>
    <td class="text-center">
      <v-btn id="pls-rewards-button" href="pls-rewards" color="primary"> PLS Rewards </v-btn>
    </td>
  </tr>
</template>
<script>
export default {
  name: "PLSRow",
  data() {
    return {
      nfts: [],
      isNFTLoading: true,
      clearIntervalX: null,
      currentDay: 0,
      plsInContract: 0,
      expandedImage: null,
    };
  },

  async mounted() {
    if (this.getUserAddress) this.readValues();
  },

  methods: {
    async readValues() {
      this.currentDay = await this.getPLSRewardsInstance.methods
        .currentDay()
        .call();
      this.plsInContract = await this.getWeb3.eth.getBalance(
        this.PLS_REWARDS_ADDRESS
      );
      this.getUserNFTs();
    },

    async getUserNFTs() {
      this.nfts = [];
      this.isNFTLoading = true;
      try {
        let ids = await this.getMINTINGInstance.methods
          .getTokenIdsByWallet(this.getUserAddress, 0, 10000)
          .call();
        let nfts = ids.map((m) => {
          return { id: m };
        });
        if (nfts.length) {
          nfts.forEach((nft, i) => {
            nft.name = `${nft.id}.png`;
            nft.image = this.geturi(nft.id);
            Promise.all([
              this.getPLSRewardsInstance.methods
                .tokenIdsToRegistered(nft.id)
                .call(),
              this.getPLSRewardsInstance.methods
                .tokenIdsToLastWithdrawalDay(nft.id)
                .call(),
              this.getPLSRewardsInstance.methods
                .tokenIdsToDailyRewardAmount(nft.id)
                .call(),
            ]).then(([isRegistered, withdrawalDay, reward]) => {
              nft.isRegistered = isRegistered;
              nft.withdrawalDay = withdrawalDay;
              nft.reward = reward;
              this.nfts.push(nft);
              if (i + 1 === nfts.length) {
                this.nfts.sort(this.compareValues("id"));
              }
              this.isNFTLoading = false;
            });
          });
        } else {
          this.isNFTLoading = false;
        }
      } catch (error) {
        this.isNFTLoading = false;
      }
    },

    getImgUrl(pet) {
      var images = require.context("../../assets/", false, /\.png$/);
      return images("./" + pet);
    },

    geturi(id) {
      return `https://carn.infura-ipfs.io/ipfs/bafybeiehpdvlkan3pucai7zfzzm3b76kju4ktqsxnqxnlbkfwrfbmvkqeu/${id}.png`;

    },

    cannotWithdrawPls(lastWithdrawalDay) {
      return Number(this.currentDay) <= Number(lastWithdrawalDay);
    },
  },
  computed: {
    NftsThatCanBeRegistered() {
      return this.nfts.filter((nft) => nft.id && !nft.isRegistered);
    },
    shouldDisableBulkRegistration() {
      return this.NftsThatCanBeRegistered.length === 0;
    },
    NftsThatCanBeWithdrawed() {
      return this.nfts.filter(
        (nft) =>
          !this.cannotWithdrawPls(nft.withdrawalDay) &&
          nft.id &&
          nft.isRegistered
      );
    },
    shouldDisableBulkWithdrawal() {
      return this.NftsThatCanBeWithdrawed.length === 0;
    },
    totalRewardOwed() {
      let totalReward = 0;
      for (let i = 0; i < this.nfts.length; i++) {
        const nft = this.nfts[i];
        if (nft.isRegistered && !this.cannotWithdrawPls(nft.withdrawalDay)) {
          totalReward +=
            ((this.currentDay - nft.withdrawalDay) * Number(nft.reward)) / 1e18;
        }
      }
      return totalReward;
    },
  },
  watch: {
    getUserAddress() {
      if (this.getUserAddress) this.readValues();
    },
  },
};
</script>
