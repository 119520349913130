<template>
  <div class="minting_card_body">
    <v-row class="justify-center">
      <div class="col-12 col-sm-5 col-xl-5">
        <v-card class="pa-8 pa-sm-16">
          <div class="text-center">
            <h2>Welcome to PulseRich!</h2>
            <h1 id="intro-header" class="mb-5 grey--text text--darken-2">
              MINT YOUR <span class="colors">PulseRich</span> NFT
            </h1>
            <b> Available NFTs </b>
            <h1 class="mb-5 grey--text text--darken-2">
              <span class="text-primary">{{ maxSupply - totalSupply }}</span> /
              {{ maxSupply }}
            </h1>
            <p class="mb-3">
              Mint {{ mintingAmount }}
              {{ isPluralBoolean(mintingAmount) ? "NFTs" : "NFT" }} for
              {{ (mintingAmount * (price / decimals)).toLocaleString() }}
              {{ currency }}
            </p>
            <div class="d-flex justify-center align-center">
              <v-btn
                fab
                color="primary"
                :disabled="!getUserAddress || isBtnLoading"
                @click="mintingAmount > 1 ? --mintingAmount : ''"
              >
                <v-icon dark> mdi-minus </v-icon>
              </v-btn>
              <input
                type="number"
                placeholder="0"
                class="mintingAmount"
                v-model.number="mintingAmount"
              />
              <v-btn
                  id="increase-nft-mint"
                fab
                color="primary"
                :disabled="!getUserAddress || isBtnLoading"
                @click="mintingAmount < 100 ? ++mintingAmount : ''"
              >
                <v-icon dark> mdi-plus </v-icon>
              </v-btn>
            </div>
            <small>
              <b
                class="d-block text-left mt-8"
                style="width: 300px; margin: auto"
                >Balance: {{ (balance / decimals).toFixed(2) }}
                {{ currency }}</b
              >
            </small>
            <v-select id="currency-select" outlined :items="tokens" v-model="currency"></v-select>

            <v-btn
                id="mint-button"
              block
              x-large
              class="mt-8 py-7"
              color="primary"
              @click="isApproved ? onMint() : onApprove()"
              :disabled="!getUserAddress || isBtnLoading"
            >
              {{
                !getUserAddress ? "Connect Wallet":
                isBtnLoading ?
                    "Loading.." :
                    isApproved ?
                        `MINT ${mintingAmount} ${isPluralBoolean(mintingAmount) ? "NFTs" : "NFT"}`:
                        `Approve ${this.currency}`
              }}
            </v-btn>
            <small id="referrer-name" v-if="isReferrer">
              <b
                class="d-block text-center mt-2"
                style="width: 300px; margin: auto"
                >Referrer: {{ referrer }}</b
              >
            </small>
            <br><br>
            <small >
              <b
                  class="d-block text-center mt-2"
                  style="width: 300px; margin: auto"
              >
                <a href="https://app.mintra.ai/collection/0x4dd104352c167e8797f8a6b90390706b53956938?category=nfts&tab=NFTs&sort=newest-first" target="_blank">SEE ALL NFTS</a>
              </b>
            </small>
          </div>
        </v-card>
      </div>
    </v-row>

    <!--    <Table v-if="getUserAddress" />-->
    <Table  />

    <br>
    <!--    <ReferrelTable v-if="getUserAddress && isReferrer" :referrerLinks="referrerLinks" />-->
    <ReferrelTable  v-if="getUserAddress" :referrerLinks="referrerLinks" />
    <br>
    <v-row>
      <img class="nft_img" src="@/assets/pr_slow2.gif" />
    </v-row>
    <v-tour name="myTour" :steps="steps" :options="{ highlight: true }"></v-tour>

  </div>
</template>

<script>
import table from "@/components/table.vue";
import referrelTable from "@/components/referrelTable.vue";
import Cookies from 'js-cookie';
import Web3 from "web3";
import ABIS from "@/config/ABIS.json";

export default {
  name: "Minting",
  components: { Table: table, ReferrelTable: referrelTable },
  data() {
    return {
      mintingAmount: 1,
      currency: "PLS",
      // Decimals
      PLSDecimals: 1e18,
      INCDecimals: 1e18,
      HEXDecimals: 1e8,
      eHEXDecimals: 1e8,
      PLSXDecimals: 1e18,
      // Balance
      PLSBalance: "",
      INCBalance: "",
      HEXBalance: "",
      eHEXBalance: "",
      PLSXBalance: "",
      // Price
      priceInPLS: "",
      priceInINC: "",
      priceInHEX: "",
      priceIneHEX: "",
      priceInPLSX: "",
      // Allowance
      INCAllowance: "",
      HEXAllowance: "",
      eHEXAllowance: "",
      PLSXAllowance: "",

      tokens: ["PLS", "PLSX", "INC", "HEX", "eHEX"],
      isBtnLoading: false,
      maxSupply: 0,
      totalSupply: 0,

      referrer: "",
      isReferrer: "",
      referrerLinks: ["Bob", "Joe"],
      steps: [



        {
          target: '#intro-header',
          content: `<strong>Would you like to take a tour?</strong>`
        },

        {
          target: '#connect-wallet',
          content: `<strong>First connect your wallet!</strong>`
        },
        {
          target: '#increase-nft-mint',
          content: `<strong>Mint more than 1 NFT at a time!</strong>`
        },
        {
          target: '#currency-select',
          content: `<strong>Mint with PLS, PLSX, Hex, eHex or INC!!</strong>`
        },
        {
          target: '#mint-button',
          content: `<strong>Click this button to mint your NFT!!</strong>`
        },
        {
          target: '#referrer-name',
          content: `<strong>If you've used a referral link, the name of the referrer will show here!</strong>`
        },
        {
          target: '#pls-rewards-button',
          content: `<strong>After you mint, click here to see your NFT's and claim your daily PLS rewards!</strong>`
        },
        {
          target: '#referrer-table',
          content: `<strong>See how much each referrer is earning with the referral links</strong>`
        },
        {
          target: '#white-paper',
          content: `<strong>Learn more about PulseRich here</strong>`
        },



      ]
    };
  },
  beforeDestroy() {
    clearInterval(this.clearIntervalX);
  },
  async mounted() {
    // if (!Cookies.get('seen-tour')) {
    //   this.$tours['myTour'].start()
    //   Cookies.set('seen-tour', true)
    //
    // }

    if (window.location.search && !Cookies.get('referrer')) {
      this.referrer = window.location.search.slice(10);
      Cookies.set('referrer', window.location.search.slice(10), { expires: 7 });
      this.isReferrer = true;

    } else if (Cookies.get('referrer')){
      this.referrer = Cookies.get('referrer')
      this.isReferrer = true;

    } else {
      this.referrer = "";
      this.isReferrer = false;
    }

    // if (window.location.search && this.referrerLinks.includes(window.location.search.slice(10)) ) {
    //   this.referrer = window.location.search.slice(10);
    //   Cookies.set('referrer', window.location.search.slice(10), { expires: 365*10 });
    //   this.isReferrer = true;
    //
    // } else if (Cookies.get('referrer')){
    //   this.referrer = Cookies.get('referrer')
    //   this.isReferrer = true;
    //
    // } else {
    //   this.referrer = "";
    //   this.isReferrer = false;
    // }

    //this.referrerLinks.includes(this.referrer)
    clearInterval(this.clearIntervalX);

    this.counter();



    let url = "https://rpc.pulsechain.com";
    //console.log("url:", url);


    let web3 = new Web3(url);
    let localMINTINGInstance = new web3.eth.Contract(
        ABIS.MINTING_ABI,
        this.MINTING_ADDRESS
    );
    Promise.all([
      localMINTINGInstance.methods.maxSupply().call(),
      localMINTINGInstance.methods.totalSupply().call(),
    ]).then(([maxSupply, totalSupply]) => {
      //console.log("maxSupply:", maxSupply);
      //console.log("totalSupply:", totalSupply);
      this.maxSupply = maxSupply;
      this.totalSupply = totalSupply;

    });








    if (this.getUserAddress) {
      this.readValues();
    }
  },
  methods: {
    async readValues() {
      clearInterval(this.clearIntervalX);
      this.counter();

      Promise.all([
        this.getMINTINGInstance.methods.maxSupply().call(),
        this.getMINTINGInstance.methods.totalSupply().call(),
        this.getWeb3.eth.getBalance(this.getUserAddress),
        this.getINCInstance.methods.balanceOf(this.getUserAddress).call(),
        this.getHEXInstance.methods.balanceOf(this.getUserAddress).call(),
        this.geteHEXInstance.methods.balanceOf(this.getUserAddress).call(),
        this.getPLSXInstance.methods.balanceOf(this.getUserAddress).call(),
        this.getMINTINGInstance.methods.priceInPLS().call(),
        this.getMINTINGInstance.methods.priceInINC().call(),
        this.getMINTINGInstance.methods.priceInHEX().call(),
        this.getMINTINGInstance.methods.priceIneHEX().call(),
        this.getMINTINGInstance.methods.priceInPLSX().call(),
        this.getINCInstance.methods
          .allowance(this.getUserAddress, this.MINTING_ADDRESS)
          .call(),
        this.getHEXInstance.methods
          .allowance(this.getUserAddress, this.MINTING_ADDRESS)
          .call(),
        this.geteHEXInstance.methods
          .allowance(this.getUserAddress, this.MINTING_ADDRESS)
          .call(),
        this.getPLSXInstance.methods
          .allowance(this.getUserAddress, this.MINTING_ADDRESS)
          .call(),
      ]).then(
        async ([
          maxSupply,
          totalSupply,
          PLSBalance,
          INCBalance,
          HEXBalance,
          eHEXBalance,
          PLSXBalance,
          priceInPLS,
          priceInINC,
          priceInHEX,
          priceIneHEX,
          priceInPLSX,
          INCAllowance,
          HEXAllowance,
          eHEXAllowance,
          PLSXAllowance,
        ]) => {
          this.maxSupply = maxSupply;
          this.totalSupply = totalSupply;

          this.PLSBalance = PLSBalance;
          this.INCBalance = INCBalance;
          this.HEXBalance = HEXBalance;
          this.eHEXBalance = eHEXBalance;
          this.PLSXBalance = PLSXBalance;
          this.priceInPLS = priceInPLS;
          this.priceInINC = priceInINC;
          this.priceInHEX = priceInHEX;
          this.priceIneHEX = priceIneHEX;
          this.priceInPLSX = priceInPLSX;
          this.INCAllowance = INCAllowance;
          this.HEXAllowance = HEXAllowance;
          this.eHEXAllowance = eHEXAllowance;
          this.PLSXAllowance = PLSXAllowance;
          let presaleAmount = 5000;
          if (this.totalSupply > presaleAmount) {
            this.priceInPLS = priceInPLS*2;
            this.priceInINC = priceInINC*2;
            this.priceInHEX = priceInHEX*2;
            this.priceIneHEX = priceIneHEX*2;
            this.priceInPLSX = priceInPLSX*2;
          }


        }
      );
    },
    counter() {
      // Hardcode the launch date and time
      const launchDateTime = "2024-03-20T12:00:00"; // Example launch date and time!!
      const launchDate = new Date(launchDateTime).getTime();

      this.clearIntervalX = setInterval(() => {
        let now = new Date().getTime();
        const distance = launchDate - now;

        // Calculate days, hours, minutes, and seconds
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        if (distance <= 0) {
          clearInterval(this.clearIntervalX);
          document.getElementById("LAUNCH_TIME").innerHTML = "0 Days 0 Hours 0 Minutes 0 Seconds";
        } else {
          document.getElementById("LAUNCH_TIME").innerHTML = `${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`;
        }
      }, 1000);
    },


    onApprove() {
      if (Number(this.mintingAmount)  > 100) {
        this.$toasted.show("Can only mint a maximum of 100 NFTs at a time");
        return;
      }
      let price = this.customToFixed(Number(this.mintingAmount) * Number(this.price));
      //console.log("got here2")
      if (price > this.balance) {
        this.$toasted.show(`Insufficient ${this.currency} balance`);
        return;
      }
      this.isBtnLoading = true;
      this.instance.methods
        .approve(this.MINTING_ADDRESS, price.toString())
        .send({
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction Hash: ", hash);
          this.$toasted.show("Approve Transaction is Submitted!");
        })
        .on("receipt", (receipt) => {
          this.readValues();
          this.isBtnLoading = false;
          console.log("Receipt: ", receipt);
          this.$toasted.show("Approved successfully!");
        })
        .on("error", (error, receipt) => {
          this.isBtnLoading = false;
          console.log("Error receipt: ", receipt);
          this.$toasted.show("Approve Transaction is Rejected!");
        });
    },
    onMint() {
      if (this.mintingAmount > 100) {
        this.$toasted.show("Can only mint a maximum of 100 NFTs at a time");
        return;
      }
      if (!this.mintingAmount) {
        this.$toasted.show("Enter Minting Amount");
        return;
      }
      if (this.currency === "PLS") this.onPLSMint();
      else this.onTokenMint();
    },
    async onTokenMint() {
      if (Number(this.mintingAmount)  > 100) {
        this.$toasted.show("Can only mint a maximum of 100 NFTs at a time");
        return;
      }
      let price = Number(this.mintingAmount) * Number(this.price);
      if (price > this.balance) {
        this.$toasted.show(`Insufficient ${this.currency} balance`);
        return;
      }
      this.isBtnLoading = true;
      let func = `${this.currency.toLocaleLowerCase()}Mint`;
      if (func == "ehexMint") {
        this.getMINTINGInstance.methods.eHexMint(this.mintingAmount, this.referrer)
            .send({
              from: this.getUserAddress,
            })
            .on("transactionHash", (hash) => {
              console.log("Transaction Hash: ", hash);
              this.$toasted.show("Your NFT is being minted");
            })
            .on("receipt", (receipt) => {
              this.readValues();
              this.isBtnLoading = false;
              console.log("Receipt: ", receipt);
              this.$toasted.show("Your NFT has been minted");
            })
            .on("error", (error, receipt) => {
              this.isBtnLoading = false;
              console.log("Error receipt: ", receipt);
              this.$toasted.show("Minting transaction has Failed");
            });
      } else if (func == 'hexMint') {
        this.getMINTINGInstance.methods.hexMint(this.mintingAmount, this.referrer)
            .send({
              from: this.getUserAddress,
            })
            .on("transactionHash", (hash) => {
              console.log("Transaction Hash: ", hash);
              this.$toasted.show("Your NFT is being minted");
            })
            .on("receipt", (receipt) => {
              this.readValues();
              this.isBtnLoading = false;
              console.log("Receipt: ", receipt);
              this.$toasted.show("Your NFT has been minted");
            })
            .on("error", (error, receipt) => {
              this.isBtnLoading = false;
              console.log("Error receipt: ", receipt);
              this.$toasted.show("Minting transaction has Failed");
            });
      } else if (func == 'plsxMint') {
        //console.log("this.mingting amount: ", this.mintingAmount)
        this.getMINTINGInstance.methods.plsxMint(this.mintingAmount, this.referrer)
            .send({
              from: this.getUserAddress,
            })
            .on("transactionHash", (hash) => {
              console.log("Transaction Hash: ", hash);
              this.$toasted.show("Your NFT is being minted");
            })
            .on("receipt", (receipt) => {
              this.readValues();
              this.isBtnLoading = false;
              console.log("Receipt: ", receipt);
              this.$toasted.show("Your NFT has been minted");
            })
            .on("error", (error, receipt) => {
              this.isBtnLoading = false;
              console.log("Error receipt: ", receipt);
              this.$toasted.show("Minting transaction has Failed");
            });
      } else if (func == 'incMint') {
        this.getMINTINGInstance.methods.incMint(this.mintingAmount, this.referrer)
            .send({
              from: this.getUserAddress,
            })
            .on("transactionHash", (hash) => {
              console.log("Transaction Hash: ", hash);
              this.$toasted.show("Your NFT is being minted");
            })
            .on("receipt", (receipt) => {
              this.readValues();
              this.isBtnLoading = false;
              console.log("Receipt: ", receipt);
              this.$toasted.show("Your NFT has been minted");
            })
            .on("error", (error, receipt) => {
              this.isBtnLoading = false;
              console.log("Error receipt: ", receipt);
              this.$toasted.show("Minting transaction has Failed");
            });
      }
    },
    customToFixed(x){
      if (Math.abs(x) < 1.0) {
        let e = parseInt(x.toString().split('e-')[1]);
        if (e) {
          x *= Math.pow(10,e-1);
          x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
      } else {
        let e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
          e -= 20;
          x /= Math.pow(10,e);
          x += (new Array(e+1)).join('0');
        }
      }
      return x;
    },
    async onPLSMint() {
      let price = this.customToFixed( Number(this.mintingAmount) * Number(this.priceInPLS));
      //console.log(typeof price)
      //console.log("balance: ", typeof this.PLSBalance)
      if (Number(price) > Number(this.PLSBalance)) {
        this.$toasted.show("Insufficient balance");
        return;
      }
      //console.log(price)


      this.isBtnLoading = true;
      this.getMINTINGInstance.methods
        .plsMint(this.mintingAmount, this.referrer)
        .send({
          value: price,
          from: this.getUserAddress,
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction Hash: ", hash);
          this.$toasted.show("Your NFT is being minted");
        })
        .on("receipt", (receipt) => {
          this.readValues();
          this.isBtnLoading = false;
          console.log("Receipt: ", receipt);
          this.$toasted.show("Your NFT has been minted");
        })
        .on("error", (error, receipt) => {
          this.isBtnLoading = false;
          console.log("Error receipt: ", receipt);
          this.$toasted.show("Minting transaction has Failed");
        });
    },
  },
  computed: {
    decimals() {
      return this[`${this.currency}Decimals`];
    },

    balance() {
      return this[`${this.currency}Balance`];
    },

    allowance() {
      return this[`${this.currency}Allowance`];
    },

    price() {
      return this[`priceIn${this.currency}`];
    },

    instance() {
      return this[`get${this.currency}Instance`];
    },

    isApproved() {
      if (this.currency === "PLS") {
        return true;
      } else {
        if (Number(this.price) && Number(this.allowance)) {
          let price = Number(this.mintingAmount) * Number(this.price);
          if (price > Number(this.allowance)) return false;
          else return true;
        } else {
          return false;
        }
      }
    },
  },
  watch: {
    async getUserAddress() {
      this.readValues();
    },
  },
};
</script>
<style>
@import "../styles/PulseBitcoinLockApp.css";
.v-main.Mint .v-main__wrap {
  background-image: url("../assets/cleaner102.jpg");
  background-size: cover;
  background-position: center;
}

.theme--light.v-application {
  background: linear-gradient(90deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

.colors {
  --bg-size: 400%;
  font-family: sans-serif;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) 0 0 /
    var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .colors {
    animation: move-bg 30s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.minting_card_body .v-card {
  background-color: rgba(250, 249, 246, 0.8) !important;
}
.minting_card_body .v-text-field__details {
  display: none;
}
.minting_card_body .v-radio .v-label,
.minting_card_body .v-input--checkbox .v-label {
  font-size: 13px;
}
.minting_card_body .v-input--selection-controls {
  margin-top: 0;
}
.minting_card_body .imageSrc {
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 10px;
}
.minting_card_body .mintingAmount {
  font-size: 45px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  width: 300px;
  height: 56px;
  border-radius: 4px;
  margin: 0 30px;
  border: 1px solid rgba(0, 0, 0, 0.38);
}

.minting_card_body .v-input__slot {
  font-size: 24px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  width: 300px;
  margin: auto;
}
.minting_card_body h1 {
  font-size: 2.2rem;
}
</style>
