<template>
  <v-row class="justify-center">
    <div class="col-12 col-sm-5 col-xl-5">
      <v-simple-table style="  background-color: rgba(250, 249, 246, 0.8) !important;">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Token Logo</th>
              <th class="text-center">You Can Withdraw</th>
              <th class="text-center">Total Pool Holds</th>
              <th class="text-center">Current Day</th>
              <th class="text-center">Action</th>
            </tr>
          </thead>
          <tbody>
            <PLSRow />
            <HEXRow />
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-row>
</template>
<script>
import PLSRow from "./rows/PLSRow.vue";
import HEXRow from "./rows/HEXRow.vue";

export default {
  name: "Table",
  components: { PLSRow: PLSRow, HEXRow: HEXRow },
  data() {
    return {};
  },
  methods: {},
};
</script>
