<template>
  <div>
    <v-row class="justify-center">
      <div class="col-12 col-sm-6 col-md-5">
        <v-card class="pa-3 text-center">
          <h1 class="colors">White Paper</h1>
          <img src="@/assets/spinning_watches.gif" class="nft_img" alt="NFT">
          <v-divider class="my-5"></v-divider>

          <section id="introduction">
            <h2>Welcome to The PulseRich NFT Collection 💰</h2>
            <p>The PulseRich Collection is a unique NFT project that celebrates the enigmatic persona of Richard Heart and his iconic fashion sense.

              This collection of 10,000 NFTs showcases various aspects of Richard Heart's personality and his love for luxury brands like Louis Vuitton and Gucci.

              The project aims to create a vibrant and engaging community of NFT enthusiasts, collectors, and fans of Richard Heart, while also providing an opportunity to participate in a yield rewarding ecosystem.</p>
          </section>
          <v-divider class="my-4"></v-divider>

          <section id="how-to-mint">
            <h2>How to Mint</h2>
            <p><router-link :to="{ name: 'Mint' }">Mint</router-link> your exclusive PulseRich NFT on the <a href="https://pulsechain.com" target="_blank">PulseChain.com</a> network using PLS, PLSX, HEX, eHEX or INC.</p>
              <p>To reward early minters, the first 5000 NFTs will be half price. </p>

            <v-container>
              <v-row>
                <v-col cols="12" md="6" lg="4" v-for="item in currencyData" :key="item.currency">
                  <v-card class="pa-3" outlined>
                    <v-card-title class="justify-center">{{ item.currency }}</v-card-title>
                    <img :src="item.imgUrl" height="50"  class="mt-2"/>

                    <v-card-text>
                      <div>
                        <span class="font-weight-bold">First 5000 NFTs:</span> {{ item.first5000 | currencyFilter }}
                      </div>
                      <div>
                        <span class="font-weight-bold">Next 5000 NFTs:</span> {{ item.next5000 | currencyFilter }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>

          </section>
          <v-divider class="my-4"></v-divider>



          <section id="rewards">
            <h2>PulseRich Rewards</h2>
            <p>🔥 <strong>Daily PLS Rewards:</strong> Register your NFTs on the <router-link :to="{ name: 'PLSRewards' }">PLS Rewards page</router-link> to unlock daily rewards in PLS</p>
            <p>🔥 Rewards will come from 5.55% of any NFT BUY/SELL on Market Places like Mintra</p>
            <p>🔥 Currently rewards are just in PLS, but other coins will be added (Hex, PLSX etc)</p>
            <p>🔥 Rewards accrue daily, even if you don't withdraw every day</p>
          </section>

          <v-divider class="my-4"></v-divider>

          <section id="referrals">
            <h2>Referral Program</h2>
            <p>🔥 Join our referral program and start earning with these steps:</p>
            <p>💰 <strong>Generate Your Referral Link:</strong> Add your wallet address to the URL like this:
              <br><code>https://pulserich.app/?referrer=[put your address here]</code>
              <br><br>Generate your own link:

            </p>



            <!-- Input for the wallet address -->
            <v-text-field label="Your Wallet Address" v-model="walletAddress" outlined></v-text-field>

            <!-- Button to generate the referral link -->
            <v-btn @click="generateReferralLink">Generate Referral Link</v-btn>

            <!-- Display the generated link -->
            <div v-if="generatedLink">
              <div>Your Referral Link:</div>
              <code>{{ generatedLink }}</code>
              <v-btn small color="primary" @click="copyToClipboard(generatedLink)">Copy</v-btn>

            </div>
            <br>
            <br>
            <br>

            <p>💸 <strong>Earn 10% of Each Mint:</strong> Share your referral link. Whenever someone clicks on it and mints an NFT, you'll receive 10% of all the mints they do going forward.</p>
            <p>🚀 Example: If someone mints 10 NFTs for 10 Million PLS after clicking your link, you'll earn 1 Million PLS.</p>
            <p>📊 <strong>Track Your Rewards:</strong> Referral rewards are sent once the collection sells out. You can track your earnings on the minting page's referral table to see how much you'll get.</p>
            <p>🔗 <strong>Custom Referral Link:</strong> For a personalized referral link like <code>pulserich.app/?referrer=richardheart</code>, DM us at <a href="https://x.com/pulseRichApp">x.com/pulseRichApp</a> with your preferred name and wallet address for rewards. Note: Custom referral links are available only if you have more than 5000 X followers.</p>
            <p>Join us at PulseRich and start earning rewards today! Reach out on Telegram at <a href="https://t.me/pulseRichApp">t.me/pulseRichApp</a> for any questions. Let's make some crypto magic happen!</p>
          </section>


          <v-divider class="my-4"></v-divider>

          <section id="smart-contracts">
            <h2>Smart Contracts</h2>
            <p>Our project leverages two core smart contracts, both of which are crucial for the minting process and the rewards system. These contracts have been meticulously audited to ensure their security and functionality. Importantly, they operate without any admin keys, providing a trustless environment for all participants. Initially, an admin key was used solely to designate the destination of the marketplace royalty fee. However, this key was renounced immediately after the project's launch, cementing the decentralized nature of our platform.</p>

                <a href="https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/address/0x4dd104352C167e8797f8a6B90390706B53956938?tab=contract" target="_blank">Minting Contract</a><br>
                <a href="https://scan.mypinata.cloud/ipfs/bafybeidn64pd2u525lmoipjl4nh3ooa2imd7huionjsdepdsphl5slfowy/#/address/0x62E38d45925b2c1E83a403A4E7A6571e86FA4c3A?tab=contract" target="_blank">Rewards Contract</a><br>
                <a href="https://etherauthority.io/pulse-rich-smart-contract-audit/" target="_blank">EtherAuthority Audit</a><br>
            <p>Our commitment to transparency and security is paramount. We invite our community to review these contracts to understand the foundational elements of our project better.</p>
          </section>


          <!--          &lt;!&ndash; Future Plans &ndash;&gt;-->
<!--          <section id="future-plans">-->
<!--            <h2>Future Plans</h2>-->
<!--            <p>🚀 <strong>Exciting Partnerships:</strong> We have MASSIVE partnerships in the works that are taking PulseRich to new heights.</p>-->
<!--          </section>-->

          <!-- Golden Ticket NFT Project -->
<!--          <section id="golden-ticket-nft-project">-->
<!--            <h2>Unlock the Exclusive Golden Ticket NFT Experience</h2>-->
<!--            <p>This collection has 15 unique, 1-of-1 NFTs that serve as your VIP pass to Lit's Inner Circle. These rare collectibles not only symbolize elite status but also unlock a world of premium opportunities, including:</p>-->
<!--            <ul>-->
<!--              <li>💡 Access to an exclusive dAPP and rewards, designed solely for Golden Ticket holders. (coming soon). This will look similar to the PLS Rewards page but with higher yield</li>-->
<!--              <li>🌟 VIP treatment and firsthand access to groundbreaking projects, special events, and unique collaborations within the Lit community.</li>-->
<!--              <li>🔑 A chance to join an elite group of visionaries and pioneers in the NFT space, paving the way for future innovations.</li>-->
<!--            </ul>-->
<!--            <p>These 15 Golden Tickets are Hidden within the 10,000 NFTs. NFTs are minted randomly, so mint some NFT's and see if you'll be a Golden Ticket Holder: </p>-->


<!--          </section>-->
          <section>
            <router-link :to="{ name: 'Mint' }">
              <v-btn color="primary" class="ma-2" @click="navigateToMintPage">
                Try To Mint Your Golden Ticket NFT
              </v-btn>
            </router-link>

            <img class="nft_img" src="@/assets/pr_slow2.gif" />
          </section>
        </v-card>
      </div>
    </v-row>
  </div>
</template>
<script>
import eUSDC from '@/assets/INC.png';
import Hex from '@/assets/hex_logo.png';
import PLS from '@/assets/pulse_logo.png';
import PLSX from '@/assets/plsx_white.png';

export default {
  data() {
    return {
      currencyData: [
        { currency: 'PLS', first5000: 500000, next5000: 1000000, imgUrl: PLS },
        { currency: 'PLSX', first5000: 1000000, next5000: 2000000, imgUrl: PLSX },
        { currency: 'INC', first5000: 10, next5000: 20, imgUrl: eUSDC },
        { currency: 'HEX', first5000: 5000, next5000: 10000, imgUrl: Hex },
        { currency: 'eHEX', first5000: 25000, next5000: 50000, imgUrl: Hex},

      ],
      walletAddress: '',
      generatedLink: '',
    };
  },
  methods: {
    generateReferralLink() {
      const baseURL = 'https://pulserich.app/?referrer=';
      if (this.walletAddress) {
        this.generatedLink = `${baseURL}${this.walletAddress}`;
      }
    },
    copyToClipboard(text) {
      if (navigator.clipboard && window.isSecureContext) {
        // Use navigator.clipboard API if available
        navigator.clipboard.writeText(text).then(() => {
        }, (err) => {
          console.error("Could not copy text: ", err);
        });
      } else {
        // Fallback method for older browsers
        let textArea = document.createElement("textarea");
        textArea.value = text;
        // Make the textarea out of viewport
        textArea.style.position = "fixed";
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          document.execCommand('copy');
        } catch (err) {
          console.error('Fallback: Oops, unable to copy', err);
        }
        document.body.removeChild(textArea);
      }
    }
  },
  filters: {
    currencyFilter(value) {
      return value.toLocaleString();
    }
  }
};
</script>
<style>
.nft_img {
  width: 325px;
  height: 325px;
  display: block;
  margin: auto;
  border-radius: 10px;
  border: 1px solid grey;
  cursor: pointer;
  position: relative;
}

.v-main.FreeCarn .v-main__wrap {
  background-image: url("../assets/hexlogos2.jpg");
  background-size: 200%;
  background-position: center;
}



.colors {
  --bg-size: 400%;
  font-family: sans-serif;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab) 0 0 /
    var(--bg-size) 100%;
  color: transparent;
  background-clip: text;
}

@media (prefers-reduced-motion: no-preference) {
  .colors {
    animation: move-bg 30s linear infinite;
  }
  @keyframes move-bg {
    to {
      background-position: var(--bg-size) 0;
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}


.expanded-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the overlay appears on top */
}

.expanded-overlay img {
  max-width: 90%;
  max-height: 90%;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 100px;
  cursor: pointer;
}


</style>
