<template>
  <tr disabled="true">
    <td class="text-center">
      <img
        :src="getImgUrl('hex_logo.png')"
        alt=""
        width="50"
        style="margin: 10px 0"
      />
    </td>
<!--    <td class="text-center">{{ totalRewardOwed }} $HEX</td>-->
<!--    <td class="text-center">{{ eightFormat(hexInContract) }} $HEX</td>-->
<!--    <td class="text-center">{{ currentDay }}</td>-->
    <td class="text-center">Coming Soon!</td>
    <td class="text-center">Coming Soon!</td>
    <td class="text-center">Coming Soon!</td>
    <td class="text-center">
      <v-btn href="hex-rewards" color="primary"> HEX Rewards </v-btn>
    </td>
  </tr>
</template>
<script>
export default {
  name: "HEXRow",
  data() {
    return {
      nfts: [],
      isNFTLoading: true,
      pulseRichAllowance: false,
      clearIntervalX: null,
      currentDay: 0,
      hexInContract: 0,
      expandedImage: null,
    };
  },

  async mounted() {
    if (this.getUserAddress) this.readValues();
  },

  methods: {
    async readValues() {
      let pulseRichAllowance = await this.getRIDEInstance.methods
        .allowance(this.getUserAddress, this.HEX_REWARDS_ADDRESS)
        .call();
      this.pulseRichAllowance = Number(pulseRichAllowance);

      let currentRegistrationFee = await this.getHEXRewardsInstance.methods
        .RICH_FEE()
        .call();
      this.currentRegistrationFee = Number(currentRegistrationFee);

      this.currentDay = await this.getHEXRewardsInstance.methods
        .currentDay()
        .call();

      this.hexInContract = await this.getHEXInstance.methods
        .balanceOf(this.HEX_REWARDS_ADDRESS)
        .call();
      //console.log("this.hexInContract:", this.hexInContract);
      this.getUserNFTs();
    },

    async getUserNFTs() {
      this.nfts = [];
      this.isNFTLoading = true;
      try {
        let ids = await this.getMINTINGInstance.methods
          .getTokenIdsByWallet(this.getUserAddress, 0, 10000)
          .call();
        let nfts = ids.map((m) => {
          return { id: m };
        });
        if (nfts.length) {
          nfts.forEach((nft, i) => {
            nft.name = `${nft.id}.png`;
            nft.image = this.geturi(nft.id);
            Promise.all([
              this.getHEXRewardsInstance.methods
                .tokenIdsToRegistered(nft.id)
                .call(),
              this.getHEXRewardsInstance.methods
                .tokenIdsToLastWithdrawalDay(nft.id)
                .call(),
              this.getHEXRewardsInstance.methods.dailyRewardAmount().call(),
            ]).then(([isRegistered, withdrawalDay, reward]) => {
              nft.isRegistered = isRegistered;
              nft.withdrawalDay = withdrawalDay;
              nft.reward = reward;
              this.nfts.push(nft);
              if (i + 1 === nfts.length) {
                this.nfts.sort(this.compareValues("id"));
              }
              this.isNFTLoading = false;
            });
          });
        } else {
          this.isNFTLoading = false;
        }
      } catch (error) {
        this.isNFTLoading = false;
      }
    },

    getImgUrl(pet) {
      var images = require.context("../../assets/", false, /\.png$/);
      return images("./" + pet);
    },

    geturi(id) {
      return `https://carn.infura-ipfs.io/ipfs/bafybeiehpdvlkan3pucai7zfzzm3b76kju4ktqsxnqxnlbkfwrfbmvkqeu/${id}.png`;
    },
    cannotWithdrawHex(lastWithdrawalDay) {
      // //console.log(typeof lastWithdrawalDay )
      // //console.log(typeof this.currentDay )
      return Number(this.currentDay) <= Number(lastWithdrawalDay);
    },
  },
  computed: {
    NftsThatCanBeRegistered() {
      return this.nfts.filter((nft) => nft.id && !nft.isRegistered);
    },
    shouldDisableBulkRegistration() {
      return this.NftsThatCanBeRegistered.length === 0;
    },
    NftsThatCanBeWithdrawed() {
      return this.nfts.filter(
        (nft) =>
          !this.cannotWithdrawHex(nft.withdrawalDay) &&
          nft.id &&
          nft.isRegistered
      );
    },
    shouldDisableBulkWithdrawal() {
      return this.NftsThatCanBeWithdrawed.length === 0;
    },
    totalRewardOwed() {
      let totalReward = 0;

      for (let i = 0; i < this.nfts.length; i++) {
        const nft = this.nfts[i];
        if (nft.isRegistered && !this.cannotWithdrawHex(nft.withdrawalDay)) {
          totalReward +=
            ((this.currentDay - nft.withdrawalDay) * Number(nft.reward)) / 1e8;
        }
      }
      return totalReward;
    },
  },
  watch: {
    getUserAddress() {
      if (this.getUserAddress) this.readValues();
    },
  },
};
</script>
