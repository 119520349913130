<template>
  <div>
<!--  <v-row class="justify-center">-->
<!--    <div class="col-12 col-sm-9 col-xl-5">-->
<!--      <h1>Referrer!</h1>-->
<!--    </div>-->
<!--  </v-row>-->
  <v-row class="justify-center" >
    <div class="col-12 col-sm-5 col-xl-5">
      <v-simple-table id="referrer-table" style="background-color: rgba(250, 249, 246, 0.8) !important;">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-center">Referrer</th>
              <th class="text-center">PLS</th>
              <th class="text-center">PLSX</th>
              <th class="text-center">INC</th>
              <th class="text-center">HEX</th>
              <th class="text-center">eHEX</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="referrer in referrers" :key="referrer.name">
              <td class="text-center">{{
                  referrer.name.length === 42 ? shortenEthAddress(referrer.name) : referrer.name == ''? "No referral" : referrer.name
                }}</td>
              <td class="text-center">{{ (eighteenFormat(referrer.plsReferrer)/10).toLocaleString() }}</td>
              <td class="text-center">{{ (eighteenFormat(referrer.plsxReferrer)/10).toLocaleString() }}</td>

              <td class="text-center">{{ (eighteenFormat(referrer.incReferrer)/10).toLocaleString() }}</td>
              <td class="text-center">{{ (eightFormat(referrer.hexReferrer)/10).toLocaleString() }}</td>
              <td class="text-center">{{ (eightFormat(referrer.ehexReferrer)/10).toLocaleString() }}</td>

            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>
  </v-row>
  </div>
</template>
<script>
export default {
  name: "ReferrerTable",
  props: ["referrerLinks"],
  data() {
    return {
      referrers: [],
    };
  },
  async mounted() {
    // Check if the contract instance is available

      try {
        // Call the getReferrerNames method from your smart contract
        const referrerNames = await this.getMINTINGInstance.methods.getReferrerNames().call();
        // Map the fetched names to initialize the referrers array
        this.referrers = referrerNames.map((name) => ({
          name: name,
          incReferrer: 0,
          hexReferrer: 0,
          ehexReferrer: 0,
          plsxReferrer: 0,
          plsReferrer: 0,
        }));

        // After setting up the initial referrers array, fetch their associated values
        this.readValues();
      } catch (error) {
        console.error("Error fetching referrer names from contract:", error);
      }

  },
  methods: {
    shortenEthAddress(address, startLength = 6, endLength = 4) {
      if (address.length === 42) {
        const start = address.slice(0, startLength);
        const end = address.slice(-endLength);
        return `${start}...${end}`;
      }
      return address;
    },
    async readValues() {
      this.referrers.forEach((e) => {
        Promise.all([
          this.getMINTINGInstance.methods.incReferrer(e.name).call(),
          this.getMINTINGInstance.methods.hexReferrer(e.name).call(),
          this.getMINTINGInstance.methods.ehexReferrer(e.name).call(),
          this.getMINTINGInstance.methods.plsxReferrer(e.name).call(),
          this.getMINTINGInstance.methods.plsReferrer(e.name).call(),
        ]).then(
          async ([
            incReferrer,
            hexReferrer,
            ehexReferrer,
            plsxReferrer,
            plsReferrer,
          ]) => {

            e.incReferrer = incReferrer;
            e.hexReferrer = hexReferrer;
            e.ehexReferrer = ehexReferrer;
            e.plsxReferrer = plsxReferrer;
            e.plsReferrer = plsReferrer;
          }
        );
      });
    },
  },
  watch: {
    async getUserAddress() {
      this.readValues();
    },
  },
};
</script>
