const getters = {
  getWeb3: (state) => {
    return state.web3;
  },
  isLoading: (state) => {
    return state.isLoading;
  },
  getUserAddress: (state) => {
    return state.userAddress;
  },
  getRIDEInstance: (state) => {
    return state.RIDEInstance;
  },
  getLOCKInstance: (state) => {
    return state.LOCKInstance;
  },
  getMINTINGInstance: (state) => {
    return state.MINTINGInstance;
  },

  getVIPInstance: (state) => {
    return state.VIPInstance;
  },

  getINCInstance: (state) => {
    return state.USDCInstance;
  },
  getHEXInstance: (state) => {
    return state.HEXInstance;
  },
  geteHEXInstance: (state) => {
    return state.eHEXInstance;
  },
  getPLSXInstance: (state) => {
    return state.PLSXInstance;
  },

  getPLSRewardsInstance: (state) => {
    return state.PLSRewardsInstance;
  },
  getHEXRewardsInstance: (state) => {
    return state.HEXRewardsInstance;
  },
  
};

export default getters;
