import Vue from "vue";
import VueRouter from "vue-router";

import Mint from "../page/Minting.vue";
import VIP from "../page/VIP.vue";
import HEXRewards from "../page/HEXRewards.vue";
import PLSRewards from "../page/PLSRewards.vue";
import WhitePaper from "../page/Whitepaper.vue";
import Disclaimer from "../page/Disclaimer.vue";


import Lock from "../page/Lock.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Mint",
    component: Mint,
  },
  {
    path: "/lock",
    name: "Lock",
    component: Lock,
  },
  {
    path: "/hex-rewards",
    name: "HEXRewards",
    component: HEXRewards,
  },
  {
    path: "/pls-rewards",
    name: "PLSRewards",
    component: PLSRewards,
  },
  {
    path: "/white_paper",
    name: "WhitePaper",
    component: WhitePaper,
  },
  {
    path: "/disclaimer",
    name: "Disclaimer",
    component: Disclaimer,
  },
  {
    path: "/vip",
    name: "VIP",
    component: VIP,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
