const state = () => ({
  web3: null,
  isLoading: false,
  userAddress: null,
  RIDEInstance: null,
  LOCKInstance: null,
  MINTINGInstance: null,
  VIPInstance: null,
  USDCInstance: null,
  HEXInstance: null,
  eHEXInstance: null,
  PLSXInstance: null,
  PLSRewardsInstance: null,
  HEXRewardsInstance: null,
});
export default state;
